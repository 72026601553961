import { inject, Injectable } from '@angular/core';
import { ProductCatalogService } from '@yol-digital/ms-client';
import { CustomerLookupService } from 'customer-lookup';
import { LanguageService } from 'language';
import { ENVIRONMENT_URLS_CONFIG_TOKEN } from 'utils';

type ProductClassEnum = ProductCatalogService.ProductClassEnum;

@Injectable({
  providedIn: 'root',
})
export class PosRedirectService {
  private config = inject(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private languageService = inject(LanguageService);
  private customerLookupService = inject(CustomerLookupService);

  getLegacyPosUrl(path: string, username?: string): string {
    let url = `${this.config.websiteUrl}/${this.languageService.current}/pos/${path}`;
    if (username) {
      url += `?username=${username}`;
    }
    return url;
  }

  getLegacyPosCheckoutUrl(productType: string, url: string, productClass: ProductClassEnum) {
    const existingCustomerUsername = this.customerLookupService.customerUsername;
    if (existingCustomerUsername) {
      return this.getLegacyPosUrl('existing-customer', existingCustomerUsername);
    }

    if (productClass === 'FIBER' || productClass === 'HFC') {
      return this.getLegacyPosUrl('products');
    }

    return this.getLegacyPosUrl(`new-${productType}/checkout/${url}`);
  }

  getPosCheckoutUrl(productCode: string) {
    return `checkout/start/${productCode}`;
  }

  getPosProductDetailsUrl(path: string) {
    return `${this.config.websiteUrl}/${this.languageService.current}/${path}`;
  }
}

<div [formGroup]="form">
  <lib-autocomplete-form-field
    [options]="nationalityOptions$ | async"
    class="w-full lg:mb-0"
    label="{{ ['checkout_details', 'nationality_label'] | translate }}"
    type="text"
    formControlName="nationality"
    placeholder="{{ ['checkout_details', 'choose_nationality'] | translate }}"
    data-testid="nationality"
    (optionSelected)="onOptionSelected.emit()"
  >
  </lib-autocomplete-form-field>
</div>

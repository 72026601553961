<lib-svg
  [svg]="brandService.isYallo() ? 'create-password' : 'create-password-lebara'"
  class="block w-2/5 m-auto [&_svg]:size-full"
/>
<p class="text-center my-6">{{ ['password', getPasswordDisclaimerKey()] | translate }}</p>

<div class="flex flex-col items-center">
  <lib-button-form-field
    type="submit"
    size="full-width"
    class="w-full"
    (click)="handlePrimaryAction()"
    [attr.data-testid]="getPrimaryActionTestId()"
  >
    {{ ['password', primaryActionTranslationKey()] | translate }}
  </lib-button-form-field>
  @if (getActionConfig().secondaryAction) {
    <a class="mt-4 cursor-pointer" (click)="handleSecondaryAction()">
      {{ ['password', secondaryActionTranslationKey()] | translate }}
    </a>
  }
</div>

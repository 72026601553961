import { Injectable } from '@angular/core';
import { ProductCatalogService } from '@yol-digital/ms-client';
import { TabItem, tabSelectorIconMap, TabSelectorKey } from './tab-selector.component';

@Injectable({
  providedIn: 'root',
})
export class TabSelectorService {
  private readonly order: TabSelectorKey[] = ['mobile', 'internet', 'tv'];

  getTabAndSubTabs(
    items: {
      family: ProductCatalogService.CatalogResponse['productSpecFamily'];
      subCategories?: string[];
      hasPromotion?: boolean;
    }[],
    includePromotions = false
  ): TabItem[] {
    if (!items || items.length === 0) return null;

    const uniqueFamilies = [...new Set(items.map(item => item.family.toLowerCase() as TabSelectorKey))];

    return uniqueFamilies
      .sort((a, b) => this.order.indexOf(a) - this.order.indexOf(b))
      .map(family => this.createTabItem(items, family, includePromotions));
  }

  private createTabItem(
    items: { family: string; subCategories?: string[]; hasPromotion?: boolean }[],
    family: TabSelectorKey,
    includePromotions: boolean
  ): TabItem {
    const filteredItems = items.filter(item => item.family.toLowerCase() === family);

    const subtabs: string[] = [
      ...(includePromotions && family === 'mobile' && filteredItems.some(item => item.hasPromotion)
        ? ['PROMOTIONS']
        : []),
      ...new Set(filteredItems.flatMap(item => item.subCategories || [])),
    ];

    return {
      tabName: family,
      icon: tabSelectorIconMap[family],
      textKey: `${family}_btn`,
      subtabs,
    };
  }
}

import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
  selector: 'lib-toggle-button',
  templateUrl: './toggle-button.component.html',
  standalone: true,
  imports: [DynamicHooksComponent, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonComponent extends FormFieldBaseComponent {
  public toggleChange = output<boolean>();

  onToggle() {
    this.control.patchValue(!this.control.value);
    this.toggleChange.emit(this.control.value);
  }
}

import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, map, Observable } from 'rxjs';
import { langUppercase } from 'interfaces';
import { LanguageService } from 'language';
import { TranslatePipe } from 'translate';
import { CountryService } from 'utils';
import { AutocompleteFormFieldComponent } from '../autocomplete-form-field/autocomplete-form-field.component';
import { SelectOption } from '../select-form-field/select-form-field.component';

@Component({
  selector: 'lib-nationality-field',
  templateUrl: './nationality-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, TranslatePipe, FormsModule, ReactiveFormsModule, AutocompleteFormFieldComponent],
})
export class NationalityFieldComponent {
  private countryService = inject(CountryService);
  private lang = inject(LanguageService);
  @Input({ required: true }) form: FormGroup;
  @Output() onOptionSelected = new EventEmitter();

  get nationality() {
    return this.form.get('nationality');
  }

  nationalityOptions$: Observable<SelectOption[]> = combineLatest([
    this.lang.onLangChange,
    this.countryService.getCountries(),
  ]).pipe(
    map(([lang, countries]) => {
      const initialcountriesList = ['Switzerland', 'Germany', 'France', 'Italy', 'Portugal'];

      // Sorting countries alphabetically by name
      countries.sort((a, b) =>
        a[lang.toUpperCase() as langUppercase].localeCompare(b[lang.toUpperCase() as langUppercase])
      );

      // moving the initial countries to the top of the list in the correct order
      countries.sort((a, b) => {
        if (initialcountriesList.includes(a.EN) && initialcountriesList.includes(b.EN)) {
          return initialcountriesList.indexOf(a.EN) - initialcountriesList.indexOf(b.EN);
        } else if (initialcountriesList.includes(a.EN)) {
          return -1;
        } else if (initialcountriesList.includes(b.EN)) {
          return 1;
        }
        return 0;
      });
      return countries
        .filter(country => country.alphaCode !== '\u0043\u0048\u004E' && country.alphaCode !== '\u0049\u0052\u0051')
        .map(country => ({
          label: country[lang.toUpperCase() as langUppercase],
          value: country.alphaCode,
        }));
    })
  );
}

<div [formGroup]="form" data-testid="form-field-id-type">
  <lib-select-form-field
    [options]="IDTypesOptions$ | async"
    class="w-full"
    label="{{ ['checkout_details', 'id_type_label'] | translate }}"
    type="text"
    formControlName="idType"
    placeholder="{{ ['checkout_details', 'choose_id'] | translate }}"
    data-testid="select-id-type"
    (optionSelected)="onOptionSelected.emit()"
  >
  </lib-select-form-field>
</div>

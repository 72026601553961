@if (text()) {
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    class="text-xs font-thin absolute fill-[--color-dark-gray] select-none"
  >
    <path
      id="textPath"
      d="M 34.429 127.229 C -4.348 95.999 5.228 34.502 51.663 16.537 C 98.097 -1.428 146.568 37.612 138.908 86.808 C 136.431 102.716 128.11 117.131 115.571 127.229 C 154.347 158.461 144.772 219.957 98.336 237.923 C 51.903 255.888 3.43 216.848 11.092 167.651 C 13.568 151.743 21.889 137.329 34.429 127.229"
      fill="none"
    />
    <text class="hidden md:xl-h:block" text-anchor="middle" letter-spacing="3">
      <textPath href="#textPath" startOffset="25%">{{ text() }}</textPath>
    </text>
  </svg>
}

@if (buttonStyleType() === 'fab') {
  <button
    mat-fab
    color="primary"
    class="!size-16 md:xl-h:!size-24 bg-gradient-to-r from-[--color-primary-default-from] to-[--color-primary-default-to] to-80%"
    [ngClass]="{ 'm-7': text(), 'opacity-40': disabled() }"
    [type]="type()"
    [disabled]="disabled()"
    [attr.disabled]="disabled()"
    [attr.data-testid]="testId()"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </button>
}

@if (buttonStyleType() === 'icon') {
  <button
    mat-icon-button
    class="hover:bg-[--color-gray-opacity]"
    [attr.data-testid]="testId()"
    (click)="onButtonClick($event)"
  >
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </button>
}

<ng-template #buttonTemplate>
  @if (loading()) {
    <img class="h-5 inline" src="/resources/small-spinner.gif" alt="" />
  } @else {
    <lib-svg [class]="iconClass()" [svg]="icon()"></lib-svg>
  }
</ng-template>

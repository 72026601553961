import { CommonModule, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

@Component({
  selector: 'lib-text-button',
  templateUrl: './text-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NgClass],
})
export class TextButtonComponent {
  public text = input<string>();
  public disabled = input(false);
  public testId = input<string>();
  public clickEvent = output<void>();

  public onButtonClick(event: MouseEvent) {
    event.stopPropagation();
    if (!this.disabled()) this.clickEvent.emit();
  }
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, input, output, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SvgComponent } from 'icon';

@Component({
  selector: 'lib-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SvgComponent, MatButtonModule, MatIconModule],
})
export class ButtonComponent {
  public text = input.required<string>();
  public svg = input<string>();
  public svgClass = input<string>();
  public customButtonClass = input<string>();
  public type = input<'mat-text-button' | 'mat-tonal-button' | 'mat-filled-button' | 'mat-outlined-button'>();
  public disabled = input(false);
  public loading = input(false);
  public role = input<'link'>();
  public clickEvent = output<void>();
  isPressed = signal(false);

  public onButtonClick(event: MouseEvent) {
    event.stopPropagation();
    if (!this.disabled()) this.clickEvent.emit();
  }

  @HostListener('mousedown') onMouseDown() {
    this.isPressed.set(true);
  }

  @HostListener('window:mouseup')
  mouseUp() {
    this.isPressed.set(false);
  }
}

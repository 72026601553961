import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';
import { AutocompleteOption } from '../autocomplete-form-field/autocomplete-form-field.component';
import { AutofocusDirective } from '../autofocus.directive';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
  selector: 'lib-custom-autocomplete-form-field',
  templateUrl: './custom-autocomplete-form-field.component.html',
  styleUrls: ['./custom-autocomplete-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    AutofocusDirective,
    SvgComponent,
    TranslatePipe,
    NgClass,
  ],
})
export class CustomAutocompleteFormFieldComponent extends FormFieldBaseComponent {
  ngControl = inject(NgControl, { optional: true, self: true });
  protected brandService: BrandService = inject(BrandService);
  @Input() options: AutocompleteOption[];
  @Input() autoFocus: boolean;
  @Input() selected = false;
  loading = input(false);
  @Output() optionSelected = new EventEmitter<AutocompleteOption>();
  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();
  @ViewChild('inputEl') inputEl: ElementRef;

  onOptionSelected(option: AutocompleteOption) {
    if (option) this.selected = true;
    else this.selected = false;
    this.optionSelected.emit(option);
  }

  public focus() {
    setTimeout(() => this.inputEl.nativeElement.focus());
  }
}

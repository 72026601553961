<div id="login" class="container p-7 overflow-x-hidden pos-login-form">
  @if (codeRequested && !phoneNumberUpdated) {
    <div class="flex flex-start cursor-pointer absolute mt-3" role="button" tabindex="0" (click)="restartUpdate()">
      <lib-svg class="md:mr-1 mt-4 stroke-dark stroke-[var(--color-dark-gray)]" svg="arrow"></lib-svg>
      <p class="font-bold text-sm hidden md:block mt-3.5">
        {{ ['login_pos', 'back'] | translate }}
      </p>
    </div>
  }
  <div class="max-w-md mx-auto">
    <div class="flex justify-center">
      <svg width="0" height="0" class="invisible">
        <linearGradient id="lg">
          <stop class="stop1" offset="0%" />
          <stop class="stop2" offset="100%" />
        </linearGradient>
      </svg>
      <lib-svg class="logo-gradient [&_svg]:size-16" svg="fontawesome/custom-gradient/user-tie"></lib-svg>
    </div>
    <h2
      class="leading-none my-6 text-center text-6xl text-transparent bg-gradient-to-bl bg-clip-text to-[--color-primary] from-[--color-blue-2]"
    >
      {{ ['login_pos', 'title'] | translate }}
    </h2>

    @if (!codeRequested) {
      <h6 class="text-center my-6 text-xl description text-slate-600">
        {{ ['login_pos', 'phone_description'] | translate }}
      </h6>
    }

    @if (!phoneNumberUpdated && codeRequested) {
      <div>
        <h6 class="text-center text-nowrap mt-2 mb-10 text-xl description text-slate-600">
          {{ ['login_pos', 'security_code_text'] | translate }}
        </h6>
      </div>
    }

    @if (phoneNumberUpdated) {
      <h6
        class="text-center mt-2 mb-24 text-xl description text-slate-600"
        [innerHtml]="['login_pos', 'account_verified_text'] | translate"
      ></h6>
    }

    @if (!phoneNumberUpdated) {
      <form [formGroup]="updatePhoneFormPos">
        <div class="mb-12 lg:mb-24">
          @if (!codeRequested) {
            <div>
              <lib-input-form-field
                #phoneInputField
                class="w-full flex-grow border-solid -mb-4"
                [theme]="'primary'"
                [checkmarkIcon]="checkIcon"
                [autoFocus]="true"
                [label]="['login_pos', 'phone_label'] | translate"
                type="text"
                formControlName="phone"
                [placeholder]="['login_pos', 'phone_placeholder'] | translate"
                data-testid="phone"
              >
              </lib-input-form-field>
            </div>
          }
          @if (codeRequested) {
            <div>
              <lib-input-form-field
                class="w-full flex-grow border-solid -mb-6"
                #codeInputField
                [checkmarkIcon]="checkIcon"
                [label]="['login', 'code_label'] | translate"
                maxlength="6"
                type="text"
                id="code"
                formControlName="code"
                data-testid="code-input"
                [mask]="'000000'"
                [maskCustomPatterns]="codeCustomPatterns"
              >
              </lib-input-form-field>
              <lib-timer />
              @if (!phoneNumberUpdated && codeRequested && timeRemaining !== 0) {
                <div class="mt-10">
                  <div class="text-center mb-6" (click)="resendCode()">
                    <button class="text-[--color-blue] font-bold cursor-pointer">
                      {{ ['login_pos', 'resend_code'] | translate }}
                    </button>
                  </div>
                </div>
              }
            </div>
          }
        </div>
        <div class="flex flex-col items-center">
          @if (!codeRequested) {
            <div class="w-full">
              <lib-button-form-field
                type="submit"
                size="full-width"
                class="w-full lg:col-span-3 lg:pt-1.5 disabled:[&>button]:!bg-[--color-gray-2]"
                [disabled]="!phoneControl.value || phoneControl.invalid"
                [loading]="requestingCode"
                data-testid="code-request-button"
                (click)="requestCode()"
              >
                {{ ['login_pos', 'code_request'] | translate }}
              </lib-button-form-field>
              <div class="mt-6">
                <div class="text-center" (click)="logout()">
                  <button class="text-[--color-blue] font-bold cursor-pointer">Logout</button>
                </div>
              </div>
            </div>
          }
          @if (codeRequested && timeRemaining !== 0) {
            <lib-button-form-field
              type="submit"
              size="full-width"
              class="w-full lg:col-span-3 lg:pt-1.5 disabled:[&>button]:!bg-[--color-gray-2]"
              [disabled]="codeControl.invalid"
              [loading]="validating"
              data-testid="code-request-button"
              (click)="confirm()"
            >
              {{ ['login_pos', 'code_request'] | translate }}
            </lib-button-form-field>
          } @else {
            @if (timeRemaining === 0) {
              <lib-button-form-field
                type="submit"
                size="full-width"
                class="w-full lg:col-span-3 lg:pt-1.5 disabled:[&>button]:!bg-[--color-gray-2]"
                data-testid="new-code-request-button"
                [loading]="validating"
                (click)="requestCode(true)"
              >
                {{ ['login_pos', 'new_code_request'] | translate }}
              </lib-button-form-field>
            }
          }
        </div>
      </form>
    }
    @if (phoneNumberUpdated) {
      <lib-button-form-field
        type="submit"
        size="full-width"
        class="w-full lg:col-span-3 lg:pt-1.5"
        (click)="logout(false)"
      >
        {{ ['login_pos', 'title'] | translate }}
      </lib-button-form-field>
    }
  </div>
</div>

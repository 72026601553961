import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { BrandService } from 'brand';
import { TranslatePipe } from 'translate';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
  selector: 'lib-radio-button-form-field',
  templateUrl: './radio-button-form-field.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [MatFormFieldModule, MatRadioModule, FormsModule, ReactiveFormsModule, TranslatePipe, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonFormFieldComponent extends FormFieldBaseComponent implements OnInit {
  protected _changeDetector = inject(ChangeDetectorRef);
  ngControl = inject(NgControl, { optional: true, self: true });
  protected brandService = inject(BrandService);
  @Output() onOptionChange = new EventEmitter<string>();
  @Input({ required: true }) options: { label: string[]; value: string }[];
  @Input() vertical = false;

  ngOnInit() {
    super.ngOnInit();
  }

  onRadioButtonChange(evt: MatRadioChange): void {
    this.onChange(evt.value);
    this.onOptionChange.emit(evt.value);
  }
}

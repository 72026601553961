import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { catchError, combineLatest, EMPTY } from 'rxjs';
import { AgentService, AuthService } from 'auth-data-access';
import { ButtonFormFieldComponent, FormFieldComponent, phoneNumberValidator } from 'form-field';
import { SvgComponent } from 'icon';
import { MsApiResponseErrorCodes } from 'interfaces';
import { LanguageService } from 'language';
import { RedirectService } from 'redirect';
import { TimerComponent, TimerService } from 'timer';
import { ToastService } from 'toast';
import { TranslatePipe, TranslateService } from 'translate';
import { transformPhoneNumber } from 'utils';

@Component({
  selector: 'lib-update-number-pos',
  imports: [
    TranslatePipe,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    SvgComponent,
    FormFieldComponent,
    ButtonFormFieldComponent,
    TimerComponent,
  ],
  templateUrl: './update-number-pos.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateNumberPosComponent implements OnInit {
  private toastService = inject(ToastService);
  private authService = inject(AuthService);
  private timerService = inject(TimerService);
  private cdr = inject(ChangeDetectorRef);
  private translateService = inject(TranslateService);
  private redirectService = inject(RedirectService);
  private languageService = inject(LanguageService);
  private agentService = inject(AgentService);
  @ViewChild('codeInputField') codeInputField: FormFieldComponent;
  public timeRemaining: number;
  public checkIcon?: string | undefined;
  public updatePhoneFormPos: FormGroup;
  public codeCustomPatterns = {
    '0': { pattern: new RegExp('[0-9]') },
  };
  public codeRequested = false;
  public requestingCode = false;
  public validating = false;
  public phoneNumberUpdated = false;
  private validators = {
    phone: [Validators.required, phoneNumberValidator(false)],
    code: [Validators.required, Validators.pattern(/^\d{6}$/)],
  };

  constructor() {
    this.timerService.timeRemaining$.pipe(takeUntilDestroyed()).subscribe(n => {
      this.timeRemaining = n;
      if (n === 0) {
        this.onTimeout();
      }
    });
  }

  ngOnInit() {
    this.updatePhoneFormPos = new FormGroup({
      phone: new FormControl(null, this.validators.phone),
      code: new FormControl(null, this.validators.code),
    });
  }

  get phoneControl() {
    return this.updatePhoneFormPos.get('phone');
  }

  get codeControl() {
    return this.updatePhoneFormPos.get('code');
  }

  public requestCode(reset = false) {
    if (this.requestingCode) {
      return;
    }
    this.requestingCode = true;

    if (reset) {
      this.timerService.removeTimer();
    }

    this.authService
      .updatePhoneNumberPosStart(transformPhoneNumber(this.phoneControl.value))
      .pipe(
        catchError(err => {
          if (!err?.wasCaught) {
            this.onError(err);
          }
          this.validating = false;
          this.requestingCode = false;
          this.codeControl.setValue(null);
          this.cdr.markForCheck();
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.codeRequested = true;
        this.requestingCode = false;
        this.codeControl.enable();
        setTimeout(() => {
          this.codeInputField.focus();
        });
        this.timerService.startTimer();
        this.cdr.markForCheck();
      });
  }

  public confirm() {
    if (this.validating) return;
    this.validating = true;
    this.authService
      .updatePhoneNumberPosConfirm(this.codeControl.value)
      .pipe(
        catchError(err => {
          if (!err?.wasCaught) {
            this.onError(err);
          }
          this.validating = false;
          this.requestingCode = false;
          this.codeControl.setValue(null);
          this.cdr.markForCheck();
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.phoneNumberUpdated = true;
        this.cdr.markForCheck();
      });
  }

  public logout(message = true) {
    combineLatest([this.agentService.agentDetails$, this.authService.logoutPos()])
      .pipe(
        catchError(err => {
          if (!err?.wasCaught) {
            this.toastService.add(this.translateService.getTranslation(['login', 'error', 'generic']), false, 1000);
          }
          return EMPTY;
        })
      )
      .subscribe(([agentDetails]) => {
        if (message) {
          this.toastService.add(this.translateService.getTranslation(['login_pos', 'logout_message']), true, 1000);
        }

        this.redirectService.redirectTo(`${this.languageService.current}/auth/login`, {
          tempChannel: agentDetails?.details?.tempChannel,
        });
      });
  }

  private onTimeout() {
    this.codeControl.reset();
    this.codeControl.disable();
    this.cdr.markForCheck();
  }

  private onError(res: HttpErrorResponse) {
    if (res.error.error === 'ERR_MAX_REACHED') {
      this.resendCode(true);
    } else {
      this.displayErrorMessage(res.error.error);
    }
    return EMPTY;
  }

  public restartUpdate() {
    this.timerService.removeTimer();
    this.codeRequested = false;
    this.codeControl.setValue(null);
    this.codeControl.markAsUntouched();
  }

  private displayErrorMessage(error: MsApiResponseErrorCodes) {
    const message =
      this.translateService.getTranslation(['login_pos', error.toLowerCase()]) ??
      this.translateService.getTranslation(['login_pos', 'generic']);
    this.toastService.add(message, false);
  }

  public resendCode(tooManyRequests = false) {
    this.codeControl.reset();
    this.requestCode(true);
    const toastMessage = tooManyRequests
      ? this.translateService.getTranslation(['login_pos', 'wrong_code_too_many_times_error_message'])
      : this.translateService.getTranslation(['login_pos', 'code_resent_successfully']);
    this.toastService.add(toastMessage);
  }
}

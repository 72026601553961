import { ChangeDetectionStrategy, Component, inject, output, OutputEmitterRef } from '@angular/core';
import { BrandService } from 'brand';
import { FeatureFlagService } from 'feature-flag';
import { ButtonFormFieldComponent } from 'form-field';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';
import { MFAPhase } from '../mfa-phases.enum';

interface PhaseConfig {
  primaryAction: OutputEmitterRef<void> | null;
  primaryTranslationKey: string | null;
  primaryActionTestId: string | null;
  secondaryAction: OutputEmitterRef<void> | null;
  secondaryTranslationKey: string | null;
}

@Component({
  selector: 'lib-create-password-disclaimer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonFormFieldComponent, TranslatePipe, SvgComponent],
  templateUrl: './create-password-disclaimer.component.html',
})
export class CreatePasswordDisclaimerComponent {
  protected brandService = inject(BrandService);
  private featureFlagService = inject(FeatureFlagService);
  public skip = output();
  public createPassword = output();

  private getMFAPhase(): MFAPhase {
    return this.featureFlagService.getFeatureValue('mfa', { phase: MFAPhase.SkipFirst }).phase;
  }

  public handlePrimaryAction() {
    const { primaryAction } = this.getActionConfig();
    primaryAction?.emit();
  }

  public handleSecondaryAction() {
    const { secondaryAction } = this.getActionConfig();
    secondaryAction?.emit();
  }

  public primaryActionTranslationKey() {
    return this.getActionConfig().primaryTranslationKey;
  }

  public secondaryActionTranslationKey() {
    return this.getActionConfig().secondaryTranslationKey;
  }

  public getPrimaryActionTestId() {
    return this.getActionConfig().primaryActionTestId;
  }

  public getActionConfig() {
    const phaseConfig: Record<MFAPhase, PhaseConfig> = {
      [MFAPhase.SkipFirst]: {
        primaryAction: this.skip,
        primaryTranslationKey: 'skip',
        primaryActionTestId: 'skip-button',
        secondaryAction: this.createPassword,
        secondaryTranslationKey: 'create_password',
      },
      [MFAPhase.CreatePasswordFirst]: {
        primaryAction: this.createPassword,
        primaryTranslationKey: 'create_password',
        primaryActionTestId: 'create-password-button',
        secondaryAction: this.skip,
        secondaryTranslationKey: 'skip',
      },
      [MFAPhase.CreatePasswordOnly]: {
        primaryAction: this.createPassword,
        primaryTranslationKey: 'create_password',
        primaryActionTestId: 'create-password-button',
        secondaryAction: null,
        secondaryTranslationKey: null,
      },
      [MFAPhase.Disabled]: {
        primaryAction: null,
        primaryTranslationKey: null,
        primaryActionTestId: null,
        secondaryAction: null,
        secondaryTranslationKey: null,
      },
    };

    return phaseConfig[this.getMFAPhase()];
  }

  getPasswordDisclaimerKey() {
    return this.getMFAPhase() === MFAPhase.CreatePasswordFirst || this.getMFAPhase() === MFAPhase.SkipFirst
      ? 'create_password_disclaimer'
      : 'create_password_only_disclaimer';
  }
}

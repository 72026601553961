import { CommonModule, TitleCasePipe, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
  ViewEncapsulation,
} from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';

export interface TabItem {
  tabName: string;
  icon: string;
  textKey: string;
  subtabs: string[];
}

export type TabSelectorKey = 'mobile' | 'internet' | 'tv'; // TODO: Get from MS

export const tabSelectorIconMap: Record<TabSelectorKey, string> = {
  mobile: 'fontawesome/custom-gradient/mobile',
  tv: 'fontawesome/custom-gradient/tv',
  internet: 'fontawesome/custom-gradient/wifi',
};

@Component({
  selector: 'lib-tab-selector',
  imports: [CommonModule, SvgComponent, NgxSkeletonLoaderModule, TitleCasePipe, UpperCasePipe],
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TabSelectorComponent {
  brand = inject(BrandService);
  public activeTab = signal(0);
  private activeTabName = computed(() => this.tabItems()?.[this.activeTab()]?.tabName);
  public activeSubTab = signal(0);
  private activeSubTabName = computed(() => this.tabItems()?.[this.activeTab()]?.subtabs[this.activeSubTab()]);
  public horizontal = input(false);
  public tabItems = input.required<TabItem[]>();
  public tabAndSubTabClick = output<{ tab: string; subtab: string }>();

  constructor() {
    effect(() => this.tabAndSubTabClick.emit({ tab: this.activeTabName(), subtab: this.activeSubTabName() }));
  }

  onTabClick(tabNumber: number): void {
    this.activeTab.set(tabNumber);
    this.activeSubTab.set(0);

    this.tabAndSubTabClick.emit({ tab: this.activeTabName(), subtab: this.activeSubTabName() });
  }

  onSubTabClick(subtabNumber: number): void {
    this.activeSubTab.set(subtabNumber);

    this.tabAndSubTabClick.emit({ tab: this.activeTabName(), subtab: this.activeSubTabName() });
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { DynamicHooksComponent } from 'ngx-dynamic-hooks';
import { BrandService } from 'brand';
import { ButtonFormFieldComponent } from 'form-field';
import { SvgComponent } from 'icon';
import { TranslatePipe } from 'translate';

@Component({
  selector: 'lib-successful-password-disclaimer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslatePipe, SvgComponent, DynamicHooksComponent, ButtonFormFieldComponent],
  templateUrl: './successful-password-disclaimer.component.html',
})
export class SuccessfulPasswordDisclaimerComponent {
  public brandService = inject(BrandService);
  @Output() loginRedirect = new EventEmitter<void>();

  redirectAfterLogin() {
    this.loginRedirect?.emit();
  }
}

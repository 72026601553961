<mat-form-field appearance="outline" [class]="class" [subscriptSizing]="desktopView ? 'fixed' : 'dynamic'">
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }

  @if (icon) {
    <lib-svg
      [svg]="icon"
      matIconPrefix
      class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
    ></lib-svg>
  }

  <input
    matInput
    #inputEl
    [libAutofocus]="autoFocus"
    [matAutocomplete]="autocomplete"
    [errorStateMatcher]="errorMatcher()"
    class="text-dark"
    [formControl]="control"
    [type]="type"
    [name]="name"
    [placeholder]="placeholder"
    [size]="size"
    [readonly]="readonly"
    (input)="_onInput($event)"
    (blur)="_onBlur()"
    (focus)="_onFocus()"
    data-testid="new-input-data"
  />
  @if (labelSuffix) {
    <span
      aria-atomic="true"
      matTextSuffix
      aria-live="polite"
      class="line-clamp-1 xl:truncate"
      [ngClass]="{ '!text-[--mdc-outlined-text-field-disabled-input-text-color] ': disabled }"
      data-testid="new-input-suffix"
      >{{ labelSuffix }}</span
    >
  }

  @if (iconSuffix) {
    <lib-svg
      [svg]="iconSuffix"
      matIconSuffix
      class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
    ></lib-svg>
  }

  @if (loading()) {
    <img matIconSuffix src="/resources/small-spinner.gif" class="h-5 ml-2 p-3 block box-content" />
  }

  @if (!loading() && ngControl.valid && ngControl.dirty && !ngControl.disabled && ngControl.value && selected) {
    <lib-svg
      matIconSuffix
      [svg]="checkmarkIcon"
      class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto p-3 block box-content"
    ></lib-svg>
  }

  <mat-autocomplete
    autoActiveFirstOption
    #autocomplete="matAutocomplete"
    (optionSelected)="onOptionSelected($event.option.value)"
    (opened)="opened.emit()"
    (closed)="closed.emit()"
  >
    @for (option of options; track option) {
      <mat-option [value]="option">
        {{ option.label }}
      </mat-option>
    }
  </mat-autocomplete>
  @if (showErrors() && controlErrorMessage && parentControl?.invalid) {
    <mat-error>
      {{ ['form_errors', controlName, controlErrorMessage] | translate }}
    </mat-error>
  }
</mat-form-field>

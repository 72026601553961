<div class="flex items-center">
  <div class="relative cursor-pointer inline-block w-12 h-6 mr-2" (click)="onToggle()">
    <input
      [attr.id]="id + '-input'"
      type="checkbox"
      [checked]="value"
      class="hidden peer"
      [ngClass]="{ checked: value }"
    />
    <span
      class="absolute inset-0 rounded-2xl bg-[var(--color-gray-2)] transition duration-400 peer-checked:bg-gradient-to-r peer-checked:from-[--color-primary-default-from] peer-checked:to-[--color-primary-default-to] peer-checked:to-80%"
    >
    </span>
    <span
      class="absolute content-'' h-[18px] w-[18px] left-[3px] bottom-[3px] bg-white transition-all rounded-full peer-checked:translate-x-6"
    >
    </span>
  </div>
  @if (label) {
    <label [attr.for]="id + '-input'" class="text-[var(--color-gray-1)] field-label font-medium">
      <ngx-dynamic-hooks [content]="label" [options]="{ sanitize: false }" />
    </label>
  }
</div>

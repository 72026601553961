import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SvgComponent } from 'icon';

type Type = 'button' | 'submit' | 'reset';
type ButtonStyleType = 'fab' | 'icon';

@Component({
  selector: 'lib-icon-button',
  templateUrl: './icon-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SvgComponent, MatButtonModule],
})
export class IconButtonComponent {
  public icon = input.required<string>();
  public text = input<string>();
  public disabled = input(false);
  public type = input<Type>('button');
  public buttonStyleType = input<ButtonStyleType>('fab');
  public loading = input(false);
  public testId = input<string>();
  public iconClass = input<string>('white medium');
  public action = output<void>();

  public onButtonClick(event: MouseEvent) {
    event.stopPropagation();
    if (!this.disabled()) this.action.emit();
  }
}

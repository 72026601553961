import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { lang } from 'interfaces';
import { TranslatePipe } from 'translate';
import { FormFieldBaseComponent } from '../form-field-base.component';

export type SelectOptionValue = string | number | object | lang;

export type SelectOption = {
  value: SelectOptionValue;
  label: string;
};

@Component({
  selector: 'lib-select-form-field',
  templateUrl: './select-form-field.component.html',
  styleUrls: ['./select-form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [MatSelectModule, FormsModule, ReactiveFormsModule, SvgComponent, TranslatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFormFieldComponent extends FormFieldBaseComponent {
  ngControl = inject(NgControl, { optional: true, self: true });
  protected brandService = inject(BrandService);
  @Input() options: SelectOption[];
  @Input() initialValue?: SelectOptionValue;
  @Output() optionSelected = new EventEmitter<SelectOptionValue>();

  selectionChange($evt: MatSelectChange) {
    this.onChange($evt.value);
    this.optionSelected.emit($evt.value);
  }
}

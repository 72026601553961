import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { SvgComponent } from 'icon';

@Component({
  selector: 'lib-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [MatDialogModule, SvgComponent, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent {
  closePosition = input<'inside' | 'outside'>('inside');
  hideCloseButton = input(false);
}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const phoneNumberValidator =
  (onlySwissNumber: boolean): ValidatorFn =>
  (control: AbstractControl<string>): ValidationErrors | null => {
    if (onlySwissNumber) {
      const pattern = /^(07(?:\s*\d){8,14})$/;
      const validLength = control.value?.replace(/\s+/g, '').length === 10;
      const invalid = !pattern.test(control.value) || !validLength;
      return invalid && control.value ? { phoneNumber: true } : null;
    }
    // This is for both international and domestic phone numbers
    const pattern = /^(00(?:\s*\d){7,14}|\+(?:\s*\d){8,14}|07(?:\s*\d){8,14})$/;
    const invalid = !pattern.test(control.value);
    return invalid ? { phoneNumber: true } : null;
  };

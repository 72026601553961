import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LineCheckService as LineCheckServiceMs } from '@yol-digital/ms-client';
import { BehaviorSubject, catchError, finalize, firstValueFrom, Observable, of, ReplaySubject, take, tap } from 'rxjs';
import { AnalyticsService } from 'analytics';
import { FeatureFlagService } from 'feature-flag';
import {
  LineCheckEligibleAccess,
  LineCheckInstallationAddress,
  LineCheckProductClass,
  LineCheckResponse,
} from 'interfaces';
import { Product } from 'product-shared';
import { ToastService } from 'toast';
import { TranslateService } from 'translate';
import { ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig, StorageKeys, StorageService } from 'utils';
import LineCheckServiceApi = LineCheckServiceMs.Api;

@Injectable({ providedIn: 'root' })
export class LineCheckProductsService {
  private storageService = inject(StorageService);
  private httpClient = inject(HttpClient);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private analyticsService = inject(AnalyticsService);
  public lineCheckResultsShown$ = new BehaviorSubject(false);
  public hasEligibleProduct$ = new BehaviorSubject(false);
  public addressPrefill$ = new ReplaySubject<LineCheckInstallationAddress>(1);
  public lineCheckResultsLoading$ = new BehaviorSubject(false);
  private lineCheckServiceApi: LineCheckServiceApi;
  public lineCheckResponse$ = new BehaviorSubject<LineCheckResponse>(null);
  private featureFlagService = inject(FeatureFlagService);

  constructor() {
    this.lineCheckServiceApi = new LineCheckServiceApi(this.config.newMicroServiceEndpoint, this.httpClient);
  }

  public async checkForAddressOnStorage(key: StorageKeys) {
    const addressLineCheck = this.lineCheckAddressFromStorage(key);
    if (addressLineCheck) {
      const resp = await firstValueFrom(this.performAddressLineCheck(addressLineCheck));
      this.lineCheckResponse$.next(resp);
      if (resp) this.saveLineCheckAddressToStorage(resp.installationAddress, key);
      this.addressPrefill$.next(addressLineCheck);
      return resp;
    }
  }

  public lineCheckAddressFromStorage(key: StorageKeys) {
    return this.storageService.get<LineCheckInstallationAddress>(key);
  }

  public lineCheckByAddress(address: LineCheckInstallationAddress): Observable<LineCheckResponse> {
    const ziraEnabledProductClasses = this.featureFlagService.getFeatureValue('zira-enabled-product-classes', '');
    return ziraEnabledProductClasses?.length > 0
      ? (this.lineCheckServiceApi.public.lineCheckByAddress(address, {
          source: 'zira',
          productSpecClass: ziraEnabledProductClasses,
        }) as Observable<LineCheckResponse>)
      : (this.lineCheckServiceApi.public.lineCheckByAddress(address) as Observable<LineCheckResponse>);
  }

  public saveLineCheckAddressToStorage(address: LineCheckInstallationAddress, key: StorageKeys) {
    this.storageService.set<LineCheckInstallationAddress>(key, address);
  }

  public removeLineCheckAddressFromStorage(key: StorageKeys) {
    this.storageService.remove(key);
  }

  public performAddressLineCheck(address: LineCheckInstallationAddress): Observable<LineCheckServiceMs.LineCheckResp> {
    this.lineCheckResultsLoading$.next(true);
    return this.lineCheckByAddress(address).pipe(
      take(1),
      catchError(err => {
        if (!err?.wasCaught) {
          return this.lineCheckErrorHandler(err);
        }
        return of(null);
      }),
      tap(res => {
        this.lineCheckResultsShown$.next(!!res);
      }),
      finalize(() => {
        this.lineCheckResultsLoading$.next(false);
      })
    );
  }

  private lineCheckErrorHandler(err: HttpErrorResponse) {
    if (err?.error.error !== 'ERR_PRODUCTS_NOT_AVAILABLE') {
      this.toastService.add(this.translateService.getTranslation(['error.generic']), false, 4000);
      console.error(err);
      return of(null);
    }

    return of({
      refId: '',
      qualifiedBy: null,
      availableProductCodes: [],
      availableProducts: [],
      installationAddress: null,
    });
  }

  public extractEligibleAccessByAddress(
    lineCheckData: LineCheckResponse,
    products: Product[]
  ): LineCheckEligibleAccess {
    const isEligibleForProduct = (productClass: LineCheckProductClass) =>
      products?.some(
        ({ product_code: code, productSpecClass }) =>
          productClass === productSpecClass && lineCheckData.availableProductCodes.includes(code)
      );

    return {
      fiber: isEligibleForProduct('FIBER'),
      hfc: isEligibleForProduct('HFC'),
      mbb: isEligibleForProduct('MBB'),
    };
  }

  public hasEligibleProduct(productType: string, eligibleAccess: LineCheckEligibleAccess) {
    return eligibleAccess[productType?.toLowerCase() as keyof LineCheckEligibleAccess] ?? false;
  }

  public hasEligibleSpecialProduct(eligibleAccess: LineCheckEligibleAccess) {
    return Object.values(eligibleAccess).some(item => item === true);
  }

  public resetLineCheckForm() {
    this.lineCheckResponse$.next(null);
    this.addressPrefill$.next(null);
    this.lineCheckResultsShown$.next(false);
  }

  public addLineCheckInfoToDatalayer(lineCheckResponse: LineCheckResponse) {
    let home_fiber_line_result = 'negative';

    if (lineCheckResponse.otoIdState === 'AVAILABLE') {
      home_fiber_line_result = 'positive oto';
    }
    if (lineCheckResponse.otoIdState === 'UNKNOWN') {
      home_fiber_line_result = 'positive unknown';
    }

    if (lineCheckResponse.otoIdState === 'UNAVAILABLE') {
      home_fiber_line_result = 'positive bep';
    }

    const data = {
      interaction_name: 'home fiber line check',
      home_fiber_line_result: home_fiber_line_result,
    };

    this.analyticsService.customEvent(data);
  }
}

.modal-container {
  .mat-mdc-dialog-container {
    position: relative;
    padding: 0;
  }

  .mdc-dialog__surface {
    --mdc-dialog-container-shape: var(--border-radius-product-boxes);
    overflow: visible;
  }
}

.transparent-modal {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: transparent !important;
    box-shadow: none !important;
    padding-top: 3rem;
    padding-right: 3rem;
  }
}

<mat-form-field
  appearance="outline"
  [class]="class"
  class="select-none"
  [subscriptSizing]="desktopView ? 'fixed' : 'dynamic'"
>
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }

  @if (icon) {
    <lib-svg
      [svg]="icon"
      matIconPrefix
      class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
    />
  }
  <mat-select
    [value]="initialValue"
    (selectionChange)="selectionChange($event)"
    disableOptionCentering
    panelClass="custom-dropdown-class"
    [formControl]="control"
    [errorStateMatcher]="errorMatcher()"
    [placeholder]="placeholder"
    (blur)="_onBlur()"
    (focus)="_onFocus()"
  >
    @for (option of options; track option) {
      <mat-option [value]="option.value">
        {{ option.label }}
      </mat-option>
    }
  </mat-select>

  @if (iconSuffix) {
    <lib-svg
      [svg]="iconSuffix"
      matIconSuffix
      class="w-6 h-6 [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] [&_svg]:w-auto [&_svg]:h-auto p-3 block box-content"
    />
  }

  @if (ngControl.valid && ngControl.dirty && !ngControl.disabled) {
    <lib-svg matIconSuffix [svg]="checkmarkIcon" class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto p-3 block box-content" />
  }
  @if (
    showErrors() &&
    controlErrorMessage &&
    ((control.errors && control.errors['required'] && !control.value) || (control.invalid && control.value))
  ) {
    <mat-error>
      {{ ['form_errors', controlName, controlErrorMessage] | translate }}
    </mat-error>
  }
</mat-form-field>

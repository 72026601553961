.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button {
  width: 1.5rem !important;
}
.mat-form-field .mat-mdc-input-element {
  line-height: unset;
}

#datePicker {
  display: none;
}

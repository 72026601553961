import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { combineLatest, EMPTY, of, Subject, timer } from 'rxjs';
import { catchError, debounce, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ToastService } from 'toast';
import { TranslatePipe, TranslateService } from 'translate';
import { AddressLineCheckPrefill, AddressLookupFields, AddressLookupService } from 'utils';
import { AutocompleteOption } from '../autocomplete-form-field/autocomplete-form-field.component';
import { CustomAutocompleteFormFieldComponent } from '../custom-autocomplete-form-field/custom-autocomplete-form-field.component';
import { postCodeValidator } from '../validators/post-code.validator';

export const DEBOUNCE_TIME = 300;

@Component({
  selector: 'lib-address-lookup-form-field',
  templateUrl: './address-lookup-form-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, ReactiveFormsModule, CustomAutocompleteFormFieldComponent, TranslatePipe],
})
export class AddressLookupFormFieldComponent implements OnDestroy, AfterContentInit, AfterViewInit {
  private addressLookupService = inject(AddressLookupService);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private cdr = inject(ChangeDetectorRef);
  @Input() addressLookupFields?: AddressLookupFields;
  @Input() containerClasses = '';
  @Input() requiredValidation = true;
  @Input() isLinecheck = true;
  @Output() postCodeValue = new EventEmitter<string>();
  @Output() cityValue = new EventEmitter<string>();
  @Output() streetValue = new EventEmitter<string>();
  @Output() streetNumberValue = new EventEmitter<string>();
  @Output() onBlur = new EventEmitter<{ field: string; control: AbstractControl }>();
  @ViewChild('postCodeInputField') postCodeInputField: CustomAutocompleteFormFieldComponent;
  @ViewChild('streetInputField') streetInputField: CustomAutocompleteFormFieldComponent;
  @ViewChild('streetNumberInputField') streetNumberInputField: CustomAutocompleteFormFieldComponent;
  inputFields: { [key: string]: CustomAutocompleteFormFieldComponent } = {};

  @Input() public set addressPrefill(address: AddressLineCheckPrefill) {
    if (address) {
      this.prefillLinecheckForm(address);
    } else {
      this.disableFormControls(['street', 'streetNumber']);
    }
  }

  @Input() public set disableInputFields(disable: boolean) {
    if (disable) {
      this.disableFormControls(['postCode', 'street', 'streetNumber']);
      this.cityOptions = [];
      this.streetOptions = [];
      this.streetNumbersOptions = [];
    } else {
      this.enableFormControls(['postCode']);
      if (this.postCode.valid) this.enableFormControls(['street']);
      if (this.street.valid) this.enableFormControls(['streetNumber']);
    }
  }

  _destroyed = new Subject<void>();
  streetOptions: AutocompleteOption[] = [];
  streetNumbersOptions: AutocompleteOption[] = [];
  addressLookupForm: FormGroup;
  cityOptions: AutocompleteOption[] = [];
  selectedPostCode = '';
  streetSelected = false;
  streetNumberSelected = false;
  customErrors = {
    cityNotFound: { cityNotFound: true },
    streetNotFound: { streetNotFound: true },
    streetNumberNotFound: { streetNumberNotFound: true },
  };
  loadingCity = signal(false);
  loadingStreet = signal(false);
  loadingStreetNumber = signal(false);

  get postCode() {
    return this.addressLookupForm.get('postCode');
  }

  get city() {
    return this.addressLookupForm.get('city');
  }

  get street() {
    return this.addressLookupForm.get('street');
  }

  get streetNumber() {
    return this.addressLookupForm.get('streetNumber');
  }

  constructor() {
    this.addressLookupForm = new FormGroup({
      postCode: new FormControl(null, [postCodeValidator]),
      city: new FormControl(null),
      street: new FormControl(null),
      streetNumber: new FormControl(null),
    });
  }

  ngAfterContentInit() {
    this.watchPostCode();
    this.watchStreets();
    this.watchStreetNumbers();
    this.setRequiredValidations();
  }

  ngAfterViewInit() {
    this.inputFields = {
      postCode: this.postCodeInputField,
      street: this.streetInputField,
      streetNumber: this.streetNumberInputField,
    };
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  setRequiredValidations() {
    if (this.requiredValidation) {
      this.postCode.addValidators(Validators.required);
      this.street.addValidators(Validators.required);
      this.streetNumber.addValidators(Validators.required);

      this.addressLookupService.addressFormValidations$.subscribe(validation => {
        if (validation) {
          if (!this.postCode.value) {
            this.postCode.markAsTouched();
            this.postCode.updateValueAndValidity();
          }
          if (!this.street.value) {
            this.street.markAsTouched();
            this.street.updateValueAndValidity();
          }
          if (!this.streetNumber.value) {
            this.streetNumber.markAsTouched();
            this.streetNumber.updateValueAndValidity();
          }
        }
      });
    }
  }

  private prefillLinecheckForm(address: AddressLineCheckPrefill) {
    this.selectedPostCode = address.postCode;
    this.selectCity(address.city, false);
    this.selectStreet(address.streetName, false);
    this.selectStreetNumber(address.streetNumber, false);
    this.disableFormControls(['postCode', 'street', 'streetNumber']);
    this.postCode.markAsDirty();
    this.street.markAsDirty();
    this.streetNumber.markAsDirty();
  }

  enableFormControls(controlNames: Array<string>) {
    for (const controlName of controlNames) {
      this.addressLookupForm.get(controlName).enable({ emitEvent: false });

      this.inputFields[controlName]?.focus();
    }
  }

  disableFormControls(controlNames: Array<string>) {
    for (const controlName of controlNames) {
      this.addressLookupForm.get(controlName).disable({ emitEvent: false });
    }
  }

  get(key: string) {
    return this.addressLookupForm.get(key).value;
  }

  set(key: string, value: string, emitEvent = true) {
    this.addressLookupForm.get(key).setValue(value, { emitEvent });
  }

  watchPostCode() {
    this.postCode.valueChanges
      .pipe(
        tap(postCode => {
          this.clearStreet();
          this.clearStreetNumber();
          this.clearCities();
          this.postCodeValue.emit(postCode);

          if (postCode) {
            this.postCode.setErrors(this.customErrors.cityNotFound);
          }
        }),
        takeUntil(this._destroyed),
        filter(postCode => postCode),
        debounce(() => timer(DEBOUNCE_TIME)),
        switchMap(postCode => {
          this.loadingCity.set(true);
          return combineLatest([
            of(postCode),
            this.addressLookupService.findCities(postCode, this.isLinecheck)?.pipe(
              catchError(error => {
                this.loadingCity.set(false);
                if (!error?.wasCaught) {
                  this.handleError(error);
                }
                return EMPTY;
              })
            ),
          ]);
        }),
        tap(() => {
          this.loadingCity.set(false);
        }),
        filter(([, cities]) => cities.length > 0)
      )
      .subscribe(([postCode, cities]: [string, string[]]) => {
        this.postCodeValue.emit(postCode);
        this.selectedPostCode = postCode;
        this.postCode.setErrors(null);

        if (cities.length === 1) {
          this.set('city', cities[0], false);
          this.cityValue.emit(cities[0]);
          this.enableFormControls(['street']);
        } else {
          this.cityOptions = cities.map((city: string) => ({ value: city, label: city }));
          this.cdr.markForCheck();
        }
      });
  }

  watchStreets() {
    this.street.valueChanges
      .pipe(
        tap(value => {
          this.streetSelected = false;
          this.street.setErrors(this.customErrors.streetNotFound);

          if (!value) {
            this.clearStreetNumber();
            this.clearCities();
          }
        }),
        takeUntil(this._destroyed),
        filter(value => value),
        debounce(() => timer(DEBOUNCE_TIME)),
        switchMap(value => {
          this.loadingStreet.set(true);
          return combineLatest([
            of(value),
            this.addressLookupService
              .findStreetNames(this.postCode.value, this.city.value, value, this.isLinecheck)
              .pipe(
                catchError(error => {
                  this.loadingStreet.set(false);
                  if (!error?.wasCaught) {
                    this.handleError(error);
                  }
                  return EMPTY;
                }),
                tap(
                  streetNames =>
                    (this.streetOptions = streetNames.map((street: string) => ({ value: street, label: street })))
                )
              ),
          ]);
        })
      )
      .subscribe(([value, streetNames]: [string, string[]]) => {
        this.loadingStreet.set(false);
        if (streetNames.includes(value)) {
          this.enableFormControls(['streetNumber']);
          this.streetSelected = true;
          this.street.setErrors(null);
          this.streetValue.emit(value);
        } else {
          this.streetSelected = false;
          this.street.setErrors(this.customErrors.streetNotFound);
          this.clearStreetNumber();
          this.streetValue.emit(undefined);
        }
        this.cdr.markForCheck();
      });
  }

  watchStreetNumbers() {
    this.streetNumber.valueChanges
      .pipe(
        tap(streetNumber => {
          this.streetNumberSelected = false;

          if (streetNumber) {
            this.streetNumber.setErrors(this.customErrors.streetNumberNotFound);
            this.deselectStreetNumber();
          }
        }),
        takeUntil(this._destroyed),
        filter(streetNumber => streetNumber),
        debounce(() => timer(DEBOUNCE_TIME)),
        switchMap(streetNumber => {
          this.loadingStreetNumber.set(true);
          return combineLatest([
            of(streetNumber),
            this.addressLookupService
              .findStreetNumbers(
                this.postCode.value,
                this.city.value,
                this.street.value,
                streetNumber,
                this.isLinecheck
              )
              .pipe(
                catchError(error => {
                  this.loadingStreetNumber.set(false);
                  if (!error?.wasCaught) {
                    this.handleError(error);
                  }
                  return EMPTY;
                }),
                tap(streetNumbers => {
                  this.streetNumbersOptions = streetNumbers.map((street: string) => ({
                    value: street,
                    label: street,
                  }));
                })
              ),
          ]);
        })
      )
      .subscribe(([value, streetNumbers]: [string, string[]]) => {
        this.loadingStreetNumber.set(false);
        if (streetNumbers.includes(value)) {
          this.streetNumberSelected = true;
          this.streetNumber.setErrors(null);
          this.streetNumberValue.emit(value);
        } else {
          this.streetNumberSelected = false;
          this.streetNumber.setErrors(this.customErrors.streetNumberNotFound);
          this.streetNumberValue.emit(undefined);
        }
        this.cdr.markForCheck();
      });
  }

  private handleError(error: HttpErrorResponse): [] {
    if (error?.status === 400) {
      this.toastService.add(this.translateService.getTranslation(['freetv.error.generic']), false); // Use the translated message
    }
    return [];
  }

  selectStreet(value: unknown, emitEvent = true) {
    const street = value as string;
    this.set('street', street, emitEvent);
    this.streetValue.emit(street);
    this.enableFormControls(['streetNumber']);
    this.deselectStreetNumber();
    this.streetSelected = true;
  }

  streetPanelOpened() {
    this.street.setErrors(null);
  }

  streetPanelClosed() {
    if (!this.streetSelected) {
      this.street.setErrors(this.customErrors.streetNotFound);
    }
  }

  selectStreetNumber(value: unknown, emitEvent = true) {
    const streetNumber = value as string;
    this.set('streetNumber', streetNumber, emitEvent);
    this.streetNumberValue.emit(streetNumber);
    this.streetNumberSelected = true;
  }

  streetNumberPanelOpened() {
    this.streetNumber.setErrors(null);
  }

  streetNumberPanelClosed() {
    if (!this.streetNumberSelected) {
      this.streetNumber.setErrors(this.customErrors.streetNumberNotFound);
    }
  }

  selectCity(value: string, emitEvent = true) {
    this.cityValue.emit(value);
    this.set('city', value, emitEvent);
    this.set('postCode', this.selectedPostCode, emitEvent);
    this.postCodeValue.emit(this.selectedPostCode);
    this.postCode.setErrors(null);
    this.clearStreet();
    this.clearStreetNumber();
    this.enableFormControls(['street']);
  }

  clearStreetNumber() {
    this.streetNumber.setValue(null, { emitEvent: false });
    this.streetNumber.setErrors(null);
    this.streetNumbersOptions = [];
    this.disableFormControls(['streetNumber']);
    this.streetNumber.updateValueAndValidity({ emitEvent: false });
    this.streetNumberValue.emit(undefined);
  }

  deselectStreetNumber() {
    this.streetNumberSelected = true;
    this.streetNumbersOptions = [];
    this.streetNumberValue.emit(undefined);
    this.streetNumber.markAsDirty();
  }

  clearStreet() {
    this.street.setValue(null, { emitEvent: false });
    this.street.setErrors(null);
    this.streetOptions = [];
    this.disableFormControls(['street']);
    this.street.updateValueAndValidity({ emitEvent: false });
    this.streetValue.emit(undefined);
  }

  clearCities() {
    if (this.postCode.value !== this.selectedPostCode) {
      this.cityOptions = [];
      this.set('city', null, false);
      this.cityValue.emit(undefined);
    }
  }

  _onBlur(field: string, control: AbstractControl) {
    this.onBlur.emit({ field, control });
  }
}

@if (tabItems()) {
  <div class="flex flex-col items-center">
    @if (tabItems().length > 1) {
      <div class="cropping flex flex-row max-w-md w-full md:max-w-xl justify-evenly md:justify-center">
        @for (tab of tabItems(); track tab.tabName) {
          <button
            (click)="onTabClick($index)"
            [ngClass]="{
              'bg-neutral-50 active shadow-inner shadow-gray-200': activeTab() === $index,
              'hover:shadow-md hover:shadow-gray-200 [&_svg]:fill-slate-400': activeTab() !== $index,
              'flex-row py-5 gap-2': horizontal(),
              'flex-col py-3 gap-1.5': !horizontal(),
            }"
            class="bg-white flex-grow px-8 gradient-icon text-slate-400 border-solid border-[0.5px] [&:not(:first-child):not(:last-child)]:border-x-0 border-neutral-200 font-bold flex w-full justify-center items-center text-sm lg:text-lg md:w-48 cursor-pointer"
          >
            <lib-svg
              class="md:[&_svg]:size-5 [&_svg]:size-3"
              [svg]="activeTab() === $index ? tab.icon : tab.icon + '-stroke'"
              [ngClass]="tab.icon"
            />
            <span
              class="font-medium text-sm"
              [ngClass]="{
                'font-bold bg-gradient-to-r from-[--color-primary-lightened] to-[--color-blue-darkened] to-80% text-transparent bg-clip-text':
                  activeTab() === $index,
              }"
            >
              {{ tab.tabName.length <= 2 ? (tab.tabName | uppercase) : (tab.tabName | titlecase) }}
            </span>
          </button>
        }
      </div>
    }

    @if (tabItems()[activeTab()]?.subtabs.length) {
      <div class="flex flex-row cropping w-full max-w-md md:max-w-xl mt-4 mb-4 justify-center mx-auto">
        @for (subTab of tabItems()[activeTab()]?.subtabs; track subTab; let i = $index) {
          <button
            (click)="onSubTabClick(i)"
            [ngClass]="{
              'bg-neutral-50 shadow-inner shadow-gray-200': activeSubTab() === i,
              'hover:shadow-md hover:shadow-gray-200': activeSubTab() !== i,
            }"
            class="h-10 border-solid border-[0.5px] [&:not(:first-child):not(:last-child)]:border-x-0 border-neutral-200 font-bold flex flex-col justify-center items-center text-sm lg:text-lg bg-white w-20 md:w-48 flex-grow text-slate-400"
          >
            <span
              class="font-medium text-xs md:text-sm"
              [ngClass]="{
                'font-bold bg-gradient-to-r from-[--color-primary-lightened] to-[--color-blue-darkened] to-80% text-transparent bg-clip-text':
                  activeSubTab() === i,
              }"
            >
              {{ subTab | titlecase }}</span
            >
          </button>
        }
      </div>
    }
  </div>
} @else {
  <div class="flex flex-col items-center">
    <ngx-skeleton-loader
      class="max-w-md w-full md:max-w-xl flex justify-evenly md:justify-center gap-1 [&_.skeleton-loader]:!h-16 [&_.skeleton-loader]:md:!h-20 [&_.skeleton-loader]:!m-0"
      count="3"
    />
  </div>
}

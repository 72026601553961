import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { filter, Observable, of, startWith, switchMap, tap, throwError } from 'rxjs';
import { LanguageService } from 'language';
import { TranslatePipe } from 'translate';
import { CountryService, PaymentTypeEnum } from 'utils';
import { SelectFormFieldComponent, SelectOption } from '../select-form-field/select-form-field.component';

@Component({
  selector: 'lib-id-type-field',
  imports: [FormsModule, ReactiveFormsModule, SelectFormFieldComponent, AsyncPipe, TranslatePipe],
  templateUrl: './id-type-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdTypeFieldComponent implements OnInit {
  private countryService = inject(CountryService);
  IDTypesOptions$: Observable<SelectOption[]>;
  private lang = inject(LanguageService);
  @Input({ required: true }) form: FormGroup;
  @Input({ required: true }) productCategory: string;
  @Output() onOptionSelected = new EventEmitter();

  get nationality() {
    return this.form.get('nationality');
  }

  get idType() {
    return this.form.get('idType');
  }

  ngOnInit(): void {
    this.idType.disable();
    this.IDTypesOptions$ = this.nationality.valueChanges.pipe(
      startWith(this.nationality.value),
      switchMap(value => {
        this.idType.disable();
        if (!value) return of(null);
        return this.countryService.getIdTypesByCountry(value, this.productCategory as PaymentTypeEnum);
      }),
      filter(Boolean),
      switchMap(response => {
        if ('error' in response) return throwError(() => response.error);
        const idTypeOptions = response.map(
          idType => <SelectOption>{ label: idType.name[this.lang.current], value: idType.id }
        );
        return of(idTypeOptions);
      }),
      tap(idTypes => {
        if (idTypes) {
          this.idType.enable();
          if (this.idType.value && !idTypes.find(option => option.value === this.idType.value)) {
            this.idType.setValue(null);
          }
        } else {
          this.idType.setValue(null);
        }
      })
    );
  }
}

<mat-form-field
  appearance="outline"
  [class]="class + ' date-picker'"
  [subscriptSizing]="desktopView ? 'fixed' : 'dynamic'"
>
  @if (label) {
    <mat-label>{{ label }}</mat-label>
  }
  <input
    id="datePicker"
    #dateInput
    matInput
    [formControl]="control"
    [errorStateMatcher]="errorMatcher()"
    [max]="maxDate"
    [min]="minDate"
    [matDatepicker]="picker"
    [matDatepickerFilter]="weekendsFilter"
    class="text-dark"
    [type]="type"
    [name]="name"
    [placeholder]="placeholder"
    [size]="size"
    [readonly]="readonly"
    (dateChange)="dateChange($event)"
    data-testid="date-picker-input"
  />
  <input
    #maskedInput
    matInput
    [(ngModel)]="dateInput.value"
    (input)="dispatch(dateInput, 'input', $event)"
    (blur)="dispatch(dateInput, 'blur')"
    (focus)="dispatch(dateInput, 'focus')"
    mask="d0/M0/0000"
  />

  @if (ngControl.valid && ngControl.value && !ngControl.disabled && !showEditIcon) {
    <lib-svg
      matIconSuffix
      [svg]="checkmarkIcon"
      class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto mr-1 block box-border"
      data-testid="checkmark-icon"
    ></lib-svg>
  }

  @if (ngControl.value && showEditIcon) {
    <lib-svg
      matIconSuffix
      [svg]="editIcon"
      class="cursor-pointer w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:text-[--color-gray-2] p-3 block box-content"
      (click)="onEditIconClick()"
    ></lib-svg>
  }

  <mat-datepicker-toggle matIconPrefix [for]="picker">
    @if (icon) {
      <lib-svg
        [svg]="icon"
        matDatepickerToggleIcon
        class="w-6 h-6 [&_svg]:w-full [&_svg]:h-auto [&_svg]:fill-[--color-gray-2] [&_svg]:text-[--color-gray-2] p-3 block box-content"
      ></lib-svg>
    }
  </mat-datepicker-toggle>

  <mat-datepicker #picker [startAt]="startCalendarDateAt"></mat-datepicker>
  @if (
    showErrors() &&
    controlErrorMessage &&
    ((parentControl.errors && parentControl.errors['required'] && !parentControl.value) ||
      (parentControl.invalid && parentControl.value))
  ) {
    <mat-error>
      {{ ['form_errors', controlName, controlErrorMessage] | translate }}
    </mat-error>
  }
</mat-form-field>

@use 'libs/resources/shared/src/lib/styles/mixins';
$menu-items: 'fontawesome/custom-gradient/mobile', 'fontawesome/custom-gradient/wifi', 'fontawesome/custom-gradient/tv';

.cropping {
  @apply rounded-2xl;

  > :first-child {
    @apply rounded-tl-2xl rounded-bl-2xl;
  }

  > :last-child {
    @apply rounded-tr-2xl rounded-br-2xl;
  }
}

.gradient-icon {
  @include mixins.gradient-icon($menu-items, true, true);
}

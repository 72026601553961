import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AuthService } from 'auth-data-access';
import { BrandService } from 'brand';
import { SvgComponent } from 'icon';
import { ReplaceTokenPipe, TranslatePipe, TranslateService } from 'translate';

@Component({
  selector: 'lib-reset-password-disclaimer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslatePipe, SvgComponent, ReplaceTokenPipe],
  templateUrl: './reset-password-disclaimer.component.html',
})
export class ResetPasswordDisclaimerComponent {
  public brandService = inject(BrandService);
  private translateService = inject(TranslateService);
  private authService = inject(AuthService);
  public loginMethodTranslation = computed(() =>
    this.authService.loginMethod()
      ? this.translateService.getTranslation(['password', this.authService.loginMethod()])
      : ''
  );
}
